<template>
  <div class="calculator">
    <div class="header">
      <ArrowLeft @click="closeCalculator" class="back-btn" />
      <p class="title">Calculadora de insumos e custo</p>
    </div>

    <div class="cards-holder">
      <div class="card">
        <div class="header">
          <Air :fillColor="'var(--blue1)'" />
          <p>Overrun (Inclusão de ar)</p>
        </div>
        <div class="input">
          <input @input="changeOverrun" v-model="overrun" type="number" />
          <p style="color: var(--blue1)">%</p>
        </div>
      </div>
      <div class="card">
        <div class="header">
          <Currency :fillColor="'var(--green1)'" />
          <p style="margin: -2px 0 0 10px">Margem lucro</p>
        </div>
        <div class="input">
          <input v-model="profitMargin" type="number" />
          <p style="color: var(--green1)">%</p>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="header">
        <Bowl :fillColor="'var(--blue1)'" />
        <p>Qtde produção</p>
      </div>
      <div class="input">
        <input
          @focus="recalculateIngredients = true"
          @blur="recalculateIngredients = false"
          v-model="totalYield"
          type="number"
          min="1"
        />
        <p style="color: var(--blue1)">{{ measurementUnit }}</p>
      </div>
    </div>

    <div v-show="ingredients.syrup.ingredients.length > 0" class="card single">
      <div class="header">
        <Waves class="icon" fillColor="var(--secondary)" />
        <p class="color-secondary">Calda base</p>
      </div>
      <div class="ingredients-holder">
        <div
          class="item"
          v-for="(item, index) in ingredients.syrup.ingredients"
          :key="index"
        >
          <div class="name">{{ item.name }}</div>
          <div class="quantity">
            {{ calculateProportion(item.quantity) }}
            {{ item.measurementUnit }}
          </div>
          <div class="price">
            <div v-show="!item.editPrice" class="price-holder">
              {{ calculateProportion(item.initialPrice, "money") | currency }}
              <EditIcon
                class="pointer"
                :size="20"
                @click="fnEditInitialPrice(item)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-show="ingredients.flavoring.length > 0" class="card single">
      <div class="header">
        <BasketIn class="icon" fillColor="var(--secondary)" />
        <p class="color-secondary">Saborização</p>
      </div>
      <div class="ingredients-holder">
        <div
          class="item"
          v-for="(item, index) in ingredients.flavoring"
          :key="index"
        >
          <div class="name">{{ item.name }}</div>
          <div class="quantity">
            {{ calculateProportion(item.quantity) }}
            {{ item.measurementUnit }}
          </div>
          <div class="price">
            <div v-show="!item.editPrice" class="price-holder">
              {{ calculateProportion(item.initialPrice, "money") | currency }}
              <EditIcon
                class="pointer"
                :size="20"
                @click="fnEditInitialPrice(item)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-show="ingredients.ingredients.length > 0" class="card single">
      <div class="header">
        <BasketIn class="icon" fillColor="var(--secondary)" />
        <p class="color-secondary">Ingredientes</p>
      </div>
      <div class="ingredients-holder">
        <div
          class="item"
          v-for="(item, index) in ingredients.ingredients"
          :key="index"
        >
          <div class="name">{{ item.name }}</div>
          <div class="quantity">
            {{ calculateProportion(item.quantity) }}
            {{ item.measurementUnit }}
          </div>
          <div class="price">
            <div v-show="!item.editPrice" class="price-holder">
              {{ calculateProportion(item.initialPrice, "money") | currency }}
              <EditIcon
                class="pointer"
                :size="20"
                @click="fnEditInitialPrice(item)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card single">
      <div class="header">
        <Currency :fillColor="'var(--green1)'" />
        <p style="color: var(--green1); margin: -2px 0 0 10px">Valores</p>
      </div>
      <div class="result-holder">
        <div v-for="(result, index) in results" :key="index" class="item">
          <div class="label weight">{{ result.label }}</div>
          <div class="label cost">
            <span>custo:</span>{{ cost(result.quantity) | currency }}
          </div>
          <div class="label profit">
            <span>lucro:</span>{{ profit(result.quantity) | currency }}
          </div>
          <div class="label total">
            <span>total</span>{{ totalMoney(result.quantity) | currency }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ArrowLeft from "vue-material-design-icons/ArrowLeft";
import Bowl from "vue-material-design-icons/BowlMix";
import Currency from "vue-material-design-icons/CurrencyUsd";
import Waves from "vue-material-design-icons/Waves";
import BasketIn from "vue-material-design-icons/BasketFill";
import Air from "vue-material-design-icons/WeatherWindy";
import EditIcon from "vue-material-design-icons/Pencil";
import CheckIcon from "vue-material-design-icons/Check";
import authentication from "../../util/authentication";
import http from "../../http";

export default {
  components: {
    ArrowLeft,
    Bowl,
    Currency,
    BasketIn,
    Waves,
    Air,
    EditIcon,
    CheckIcon,
  },
  props: ["parentRecipe", "editedIngredient"],
  data() {
    return {
      recipe: { ...this.parentRecipe },
      totalYield: 10,
      initialYield: 0,
      measurementUnit: "KG",
      profitMargin: 10,
      overrun: 0,
      recalculateIngredients: false,
    };
  },
  computed: {
    results() {
      return [
        {
          label: `Porção (100${this.recipe.measurementUnit})`,
          quantity: 100 / (this.overrun / 100 + 1), // gr/ml
        },
        {
          label: `Porção (1${
            this.recipe.measurementUnit == "gr" ? "kg" : "L"
          })`,
          quantity: 1000 / (this.overrun / 100 + 1), // gr/ml
        },
        {
          label: `${this.totalYield}${
            this.recipe.measurementUnit == "gr" ? "kg" : "L"
          }`,
          quantity:
            this.measurementUnit.toLowerCase() == "kg" ||
            this.measurementUnit.toLowerCase() == "l"
              ? this.initialYield * 1000
              : this.initialYield, // gr/ml
        },
      ];
    },
    ingredients() {
      let ingredients;
      if (this.recipe?.ingredients) {
        ingredients = JSON.parse(this.recipe.ingredients);
        ingredients.syrup.ingredients.map((item) => {
          item.initialPrice = item.price;
          item.editPrice = false;
          return item;
        });
        ingredients.flavoring.map((item) => {
          item.initialPrice = item.price;
          item.editPrice = false;
          return item;
        });
        ingredients.ingredients.map((item) => {
          item.initialPrice = item.price;
          item.editPrice = false;
          return item;
        });
      }
      return ingredients
        ? ingredients
        : {
            overrun: 0,
            syrup: { name: null, ingredients: [] },
            flavoring: [],
            ingredients: [],
          };
    },
  },
  methods: {
    fnEditInitialPrice(item) {
      this.$emit("showIngredientModal", item);
    },
    changeOverrun() {
      this.totalYield =
        Number(this.initialYield) +
        (Number(this.initialYield) * Number(this.overrun)) / 100;
    },
    calculateProportion(value, type = "measurementUnit") {
      if (this.totalYield <= 0) return;
      let proportion, factor;
      if (
        (this.measurementUnit == "KG" &&
          this.recipe.measurementUnit.toLowerCase() == "gr") ||
        (this.measurementUnit == "L" &&
          this.recipe.measurementUnit.toLowerCase() == "ml")
      ) {
        factor = 1000;
      } else {
        factor = 1;
      }
      if (this.ingredients?.overrun) {
        proportion =
          (this.initialYield * factor * (this.ingredients.overrun / 100 + 1)) /
          this.recipe.yield;
      } else {
        proportion = (this.initialYield * factor) / this.recipe.yield;
      }
      return type === "money"
        ? (value * proportion).toFixed(2)
        : (value * proportion).toFixed(0);
    },
    recipeCost() {
      let total = 0;
      this.ingredients.syrup.ingredients.map((ing) => {
        total += Number(ing.initialPrice);
      });
      this.ingredients.flavoring.map((ing) => {
        total += Number(ing.initialPrice);
      });
      this.ingredients.ingredients.map((ing) => {
        total += Number(ing.initialPrice);
      });
      return total;
    },
    cost(quantity) {
      let proportion;
      if (
        this.recipe.measurementUnit.toLowerCase() == "kg" ||
        this.recipe.measurementUnit.toLowerCase() == "l"
      ) {
        proportion = quantity / (this.recipe.yield * 1000);
      } else {
        proportion = quantity / this.recipe.yield;
      }
      if (this.ingredients.overrun) {
        return (
          this.recipeCost() * proportion * (this.ingredients.overrun / 100 + 1)
        );
      }
      return this.recipeCost() * proportion;
    },
    profit(quantity) {
      return (this.cost(quantity) * this.profitMargin) / 100;
    },
    totalMoney(quantity) {
      return this.cost(quantity) + this.profit(quantity);
    },
    closeCalculator() {
      this.$emit("showCalculator", false);
    },
    setUserPriceForIngredient(ingredient, userIngredient) {
      if (
        ingredient.name === userIngredient?.name &&
        userIngredient?.weight &&
        userIngredient?.price
      ) {
        ingredient.initialPrice =
          (ingredient.quantity / userIngredient.weight) * userIngredient.price;
      }
    },
    applyUserIngredients(userIngredients) {
      for (let i = 0; i < userIngredients.length; i++) {
        this.ingredients.syrup.ingredients.map((ingredient) => {
          this.setUserPriceForIngredient(ingredient, userIngredients[i]);
        });
        this.ingredients.flavoring.map((ingredient) => {
          this.setUserPriceForIngredient(ingredient, userIngredients[i]);
        });
        this.ingredients.ingredients.map((ingredient) => {
          this.setUserPriceForIngredient(ingredient, userIngredients[i]);
        });
      }
      this.$forceUpdate();
    },
    getUserIngredients() {
      let ingredients = [];
      ingredients = [
        ...ingredients,
        ...this.ingredients?.syrup?.ingredients?.map((item) => {
          return item.name;
        }),
      ];
      ingredients = [
        ...ingredients,
        ...this.ingredients?.flavoring?.map((item) => {
          return item.name;
        }),
      ];
      ingredients = [
        ...ingredients,
        ...this.ingredients?.ingredients?.map((item) => {
          return item.name;
        }),
      ];
      const params = {
        page: 0,
        take: -1,
        ingredients: ingredients,
      };
      if (ingredients.length < 1) return;

      http
        .post("ingredient/list", params, authentication.getHeaders())
        .then((response) => {
          this.applyUserIngredients(response.data?.list);
        });
    },
    initCalculator() {
      if (
        this.recipe.measurementUnit.toLowerCase() === "gr" ||
        this.recipe.measurementUnit.toLowerCase() === "ml"
      ) {
        this.totalYield = this.recipe.yield / 1000; // converting gr and ml to KG and L
        this.initialYield = this.totalYield;
      }
      if (this.recipe.measurementUnit.toLowerCase() === "ml") {
        this.measurementUnit = "L";
      }

      if (authentication.isLoggedIn()) {
        this.getUserIngredients();
      }
    },
  },
  watch: {
    totalYield: {
      handler(value) {
        if (this.recalculateIngredients) {
          if (Number(this.overrun) > 0) {
            let parts = Number(this.overrun) / 100 + 1;
            this.initialYield = value / parts;
            console.log(this.initialYield);
          } else {
            this.initialYield = value;
          }
        }

        this.ingredients?.syrup?.ingredients?.map((item) => {
          item.price = this.calculateProportion(item.initialPrice, "money");
        });
        this.ingredients?.flavoring?.map((item) => {
          item.price = this.calculateProportion(item.initialPrice, "money");
        });
        this.ingredients?.ingredients?.map((item) => {
          item.price = this.calculateProportion(item.initialPrice, "money");
        });
      },
    },
    parentRecipe: {
      handler(value) {
        this.recipe = { ...value };
        this.initCalculator();
      },
      immediate: true,
    },
    ingredients: {
      handler(value) {
        let recipeOverrun = value?.overrun ? Number(value.overrun) : 0;
        this.overrun = recipeOverrun;
        if (recipeOverrun > 0) {
          let parts = recipeOverrun / 100 + 1;
          this.initialYield = this.totalYield / parts;
        }
      },
      immediate: true,
    },
    editedIngredient: {
      handler(userIngredient) {
        this.applyUserIngredients([userIngredient]);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.calculator {
  position: fixed;
  width: 100vw;
  max-width: 600px;
  height: 80vh;
  padding: 20px 15px 35px 15px;
  background-color: #f1f1f1;
  border-radius: 14px 14px 0 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  overflow: auto;
  box-shadow: 0 -5px 10px 0 rgba(0, 0, 0, 0.12);
  z-index: 1000;
  @media screen and (min-width: 1160px) {
    height: 100vh;
    left: initial;
    border-radius: 14px 0 0 14px;
  }
  > .header {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      position: absolute;
      left: 0;
      margin-top: 2px;
    }
    p {
      font-size: 1.32em;
      color: var(--primary);
    }
    .back-btn {
      cursor: pointer;
    }
  }

  &::-webkit-scrollbar {
    width: 0.5em;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--dark4);
    outline: 1px solid slategrey;
  }
}

.cards-holder {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}

.card {
  position: relative;
  padding: 12px;
  border-radius: 12px;
  margin-top: 25px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  &.single {
    margin-top: 20px;
  }
  .header {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    p {
      margin-left: 15px;
      font-family: fontLight;
      font-size: 1.05em;
    }
  }
}

.input {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
  input {
    position: relative;
    min-width: 0;
    width: 50%;
    min-width: 64px;
    max-width: 220px;
    height: 38px;
    min-width: 0;
    font-size: 2em;
    border-bottom: 1px solid #777;
    text-align: center;
  }
  p {
    font-family: fontLight;
    font-size: 3.2em;
  }
}

.material-design-icon.icon {
  height: 2em;
  width: 2em;
}

.material-design-icon.icon > .material-design-icon__svg {
  height: 2em;
  width: 2em;
}

.ingredients-holder {
  position: relative;
  margin-top: 10px;
  .item {
    position: relative;
    width: 100%;
    padding: 8px;
    display: grid;
    grid-template-columns: 1fr 0.45fr 0.55fr;
    gap: 0.5em;
    justify-content: space-between;
    align-items: center;
    &:nth-child(even) {
      background-color: #f1f1f1;
    }
    .name {
      color: var(--dark4);
      font-size: 1.05em;
    }
    .quantity {
      text-align: right;
    }
    .price {
      text-align: right;
      height: 100%;
      input {
        text-align: right;
        background-color: transparent;
        width: 100%;
        height: 100%;
        color: #0089ff;
      }
    }
  }
}

.price-holder {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.result-holder {
  position: relative;
  margin-top: 10px;
  width: 100%;
  overflow-x: auto;
  .item {
    position: relative;
    width: 100%;
    padding: 8px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: space-between;
    align-items: center;
    &:not(:last-child) {
      border-bottom: 1px dashed #ccc;
    }
    .label {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0 10px;
      span {
        color: var(--dark5);
      }
    }
    .weight {
      color: var(--dark4);
      font-size: 1.05em;
    }
    .cost {
      text-align: left;
      color: var(--secondary);
    }
    .profit {
      text-align: left;
      color: var(--green1);
    }
    .total {
      color: var(--primary);
    }
  }
}
</style>
