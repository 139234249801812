<template>
  <div>
    <Header
      :showBackButton="true"
      :showLogo="false"
      :menuColor="'#fff'"
      class="page-anim1"
    />

    <transition enter-active-class="animated fadeInDown">
      <div
        v-show="recipe.recipeImage.length > 0"
        id="slider"
        class="swiper-container"
      >
        <div class="swiper-wrapper">
          <div
            v-for="img in recipe.recipeImage"
            :key="img.id"
            class="img-holder swiper-slide"
            :style="`background-image:url(${img.path})`"
          ></div>
        </div>
        <div
          v-show="recipe.recipeImage.length > 1"
          id="sliderPrev"
          class="banner-prev"
        >
          <ChevronLeft class="icon" />
        </div>
        <div
          v-show="recipe.recipeImage.length > 1"
          id="sliderNext"
          class="banner-next"
        >
          <ChevronRight class="icon" />
        </div>
      </div>
    </transition>

    <div class="holder container">
      <div class="main">
        <div class="container">
          <div
            v-show="loading"
            class="flex-center desktop12 tablet12 mobile12 mt-3"
          >
            <div class="loading2"></div>
          </div>

          <div
            class="flex-between mobile12 tablet12 desktop12"
            style="align-items: center"
          >
            <div class="reactions">
              <transition enter-active-class="animated fadeInLeft">
                <div v-show="recipe.likes" class="item">
                  <Liked />
                  <p>{{ `(${recipe.likes})` }}</p>
                </div>
              </transition>
              <transition enter-active-class="animated fadeInLeft">
                <div v-show="recipe.favorited" class="item">
                  <Favorited />
                  <p>{{ `(${recipe.favorited})` }}</p>
                </div>
              </transition>
            </div>
            <!-- <transition enter-active-class="animated fadeInRight">
              <div v-show="recipe.title" class="desktop-hidden">
                <Share
                  class="btn-share"
                  fillColor="var(--secondary)"
                  :size="30"
                />
              </div>
            </transition> -->
          </div>

          <transition enter-active-class="animated zoomIn">
            <h1 v-show="recipe.title" class="title">{{ recipe.title }}</h1>
          </transition>

          <transition enter-active-class="animated zoomIn">
            <p v-show="recipe.description" class="desc">
              {{ recipe.description }}
            </p>
          </transition>

          <transition enter-active-class="animated zoomIn">
            <div
              v-show="ingredients.syrup.name"
              class="title-holder"
              style="animation-delay: 250ms"
            >
              <Waves class="icon" />
              <h1>{{ ingredients.syrup.name }}</h1>
            </div>
          </transition>

          <transition enter-active-class="animated zoomIn">
            <table
              v-show="ingredients.syrup.ingredients.length > 0"
              class="ingredients"
              style="animation-delay: 250ms"
            >
              <tbody>
                <tr
                  v-for="(item, index) in ingredients.syrup.ingredients"
                  :key="index"
                >
                  <td
                    @click="navigateToProduct(item)"
                    :class="{ pointer: item.slug }"
                  >
                    <img
                      class="ingredient-image"
                      v-show="item.image"
                      :src="item.image"
                    />
                    {{ item.name }}
                  </td>
                  <td>{{ `${item.quantity} ${item.measurementUnit}` }}</td>
                  <!-- <td>{{ item.price | currency }}</td> -->
                </tr>
              </tbody>
            </table>
          </transition>

          <transition enter-active-class="animated zoomIn">
            <div
              v-show="ingredients.flavoring.length > 0"
              class="title-holder"
              style="animation-delay: 250ms"
            >
              <BasketIn class="icon" />
              <h1>Saborização</h1>
            </div>
          </transition>

          <transition enter-active-class="animated zoomIn">
            <table
              v-show="ingredients.flavoring.length > 0"
              class="ingredients"
              style="animation-delay: 250ms"
            >
              <tbody>
                <tr v-for="(item, index) in ingredients.flavoring" :key="index">
                  <td
                    @click="navigateToProduct(item)"
                    :class="{ pointer: item.slug }"
                  >
                    <img
                      class="ingredient-image"
                      v-show="item.image"
                      :src="item.image"
                    />
                    {{ item.name }}
                  </td>
                  <td>{{ `${item.quantity} ${item.measurementUnit}` }}</td>
                  <!-- <td>{{ item.price | currency }}</td> -->
                </tr>
              </tbody>
            </table>
          </transition>

          <div
            v-show="ingredients.ingredients.length > 0"
            class="title-holder"
            style="animation-delay: 500ms"
          >
            <BasketIn class="icon" />
            <h1>Ingredientes</h1>
          </div>

          <table
            v-show="ingredients.ingredients.length > 0"
            class="ingredients"
            style="animation-delay: 500ms"
          >
            <tbody>
              <tr v-for="(item, index) in ingredients.ingredients" :key="index">
                <td
                  @click="navigateToProduct(item)"
                  :class="{ pointer: item.slug }"
                >
                  <img
                    class="ingredient-image"
                    v-show="item.image"
                    :src="item.image"
                  />
                  {{ item.name }}
                </td>
                <td>{{ `${item.quantity} ${item.measurementUnit}` }}</td>
                <!-- <td>{{ item.price | currency }}</td> -->
              </tr>
            </tbody>
          </table>

          <transition enter-active-class="animated zoomIn">
            <div
              v-show="ingredients.overrun > 0"
              class="flex-start prepare desktop12 tablet12 mobile12"
            >
              <div class="title-holder prepare">
                <Air class="icon" />
                <h1>Inclusão de Ar</h1>
              </div>

              <p class="desc yield">
                {{ `${ingredients.overrun} %` }}
              </p>
            </div>
          </transition>

          <transition enter-active-class="animated zoomIn">
            <div v-show="recipe.prepareMode" class="title-holder">
              <Bowl class="icon" />
              <h1>Modo de preparo</h1>
            </div>
          </transition>

          <p class="desc" v-html="recipe.prepareMode"></p>

          <transition enter-active-class="animated zoomIn">
            <div
              v-show="recipe.yield"
              class="flex-start prepare desktop12 tablet12 mobile12"
            >
              <div class="title-holder prepare">
                <Basket class="icon" />
                <h1>Rendimento</h1>
              </div>

              <p class="desc yield">
                {{ `${recipe.yield} ${recipe.measurementUnit}` }}
              </p>
            </div>
          </transition>
        </div>
      </div>

      <div class="related container mt-2-mobile">
        <div v-show="recipe.relatedRecipes.length > 0" class="title-holder">
          <h1>Você também pode gostar</h1>
        </div>

        <div class="related-holder">
          <RecipeCard
            v-for="recipe in recipe.relatedRecipes"
            :key="recipe.id"
            :id="recipe.id"
            :title="recipe.title"
            :description="recipe.description"
            :image="recipe.coverImage"
            :likes="recipe.likes"
            :slug="recipe.slug"
          />
        </div>
      </div>
    </div>

    <transition enter-active-class="animated fadeInUp">
      <Navbar
        v-show="recipe.id"
        :id="recipe.id"
        :isFavorite="recipe.isFavorite"
        :liked="recipe.isLike"
        @openCalculator="toggleCalculator"
        @favorite="changeFavorite"
        @like="changeLike"
      />
    </transition>

    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div
        @click="toggleCalculator(false)"
        v-show="showCalculator"
        class="bg-cover"
      ></div>
    </transition>

    <transition
      enter-active-class="animated fadeInUp"
      leave-active-class="animated fadeOutDown"
    >
      <Calculator
        v-show="showCalculator"
        :parentRecipe="recipe"
        :editedIngredient="selectedIngredient"
        @showCalculator="toggleCalculator"
        @showIngredientModal="fnShowIngredientModal"
      />
    </transition>

    <transition
      enter-active-class="animated fadeInUp"
      leave-active-class="animated fadeOutDown"
    >
      <IngredientModal
        @close="toggleCalculator"
        @saveIngredient="saveUserIngredient"
        v-show="showIngredientModal"
        :ingredientData="selectedIngredient"
      />
    </transition>
  </div>
</template>
<script>
import http from "@/http";
import authentication from "../util/authentication";
import Header from "../components/Header";
import Navbar from "../components/recipe/Actions";
import Calculator from "../components/recipe/Calculator";
import RecipeCard from "../components/RecipeCard";
import IngredientModal from "../components/IngredientModal";
import Liked from "vue-material-design-icons/ThumbUp";
import Favorited from "vue-material-design-icons/Heart";
import Bowl from "vue-material-design-icons/BowlMix";
import Basket from "vue-material-design-icons/BasketUnfill";
import Waves from "vue-material-design-icons/Waves";
import BasketIn from "vue-material-design-icons/BasketFill";
import ChevronLeft from "vue-material-design-icons/ChevronLeft";
import ChevronRight from "vue-material-design-icons/ChevronRight";
import Air from "vue-material-design-icons/WeatherWindy";

import Swiper from "swiper/bundle";
import "swiper/swiper-bundle.css";

export default {
  name: "Home",
  components: {
    Header,
    Navbar,
    Calculator,
    RecipeCard,
    IngredientModal,
    Liked,
    Favorited,
    Bowl,
    Basket,
    Waves,
    BasketIn,
    ChevronLeft,
    ChevronRight,
    Air,
  },
  data() {
    return {
      loading: true,
      showCalculator: false,
      showIngredientModal: false,
      selectedIngredient: null,
      recipe: {
        title: "",
        description: "",
        ingredients: "",
        prepareMode: "",
        coverImage: "",
        yield: null,
        measurementUnit: "",
        slug: "",
        recipeCategoryId: "",
        likes: null,
        favorited: null,
        isFavorite: false,
        liked: false,
        recipeImage: [],
        recipeCategory: {
          name: "",
          slug: "",
        },
        relatedRecipes: [],
      },
    };
  },
  created() {
    this.getRecipe();
  },
  mounted() {
    window.scroll({
      top: 0,
      left: 0,
    });
  },
  computed: {
    ingredients() {
      return this.recipe.ingredients
        ? JSON.parse(this.recipe.ingredients)
        : {
            overrun: 0,
            syrup: { name: null, ingredients: [] },
            flavoring: [],
            ingredients: [],
          };
    },
  },
  beforeDestroy() {
    let doc = document.querySelector("html");
    doc.style.overflowY = "initial";
  },
  methods: {
    startSlider() {
      setTimeout(() => {
        new Swiper("#slider", {
          slidesPerView: 1,
          spaceBetween: 0,
          autoHeight: true,
          navigation: {
            nextEl: "#sliderNext",
            prevEl: "#sliderPrev",
          },
        });
      }, 200);
    },
    changeFavorite() {
      this.recipe.isFavorite = !this.recipe.isFavorite;
    },
    changeLike() {
      this.recipe.isLike = !this.recipe.isLike;
    },
    navigateToProduct(ingredient) {
      if (ingredient.productId) {
        this.$router.push(`/produto/${ingredient.productId}`);
      }
    },
    saveUserIngredient(ingredient) {
      this.selectedIngredient = ingredient;
      this.toggleCalculator();
    },
    fnShowIngredientModal(ingredient) {
      this.showIngredientModal = true;
      if (authentication.isLoggedIn()) {
        const params = {
          page: 0,
          take: -1,
          ingredients: [ingredient.name],
        };
        http
          .post("ingredient/list", params, authentication.getHeaders())
          .then((response) => {
            if (response.data?.list?.length > 0) {
              this.selectedIngredient = response.data?.list[0];
            } else {
              this.selectedIngredient = ingredient;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.selectedIngredient = ingredient;
      }
    },
    toggleCalculator(val) {
      if (this.showIngredientModal) {
        this.showIngredientModal = false;
      } else {
        this.showCalculator = val;
        let doc = document.querySelector("html");
        if (val) {
          doc.style.overflowY = "hidden";
        } else {
          doc.style.overflowY = "initial";
        }
      }
    },
    getRecipe() {
      this.loading = true;
      http
        .get(
          `recipe/details?id=${this.$route.params.id}`,
          authentication.getHeaders()
        )
        .then((response) => {
          this.recipe = response.data;
          localStorage.recipe = JSON.stringify(this.recipe);
          this.startSlider();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.img-holder {
  position: relative;
  width: 100%;
  height: 250px;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 70px;
    background: linear-gradient(180deg, #000000bb, transparent);
    top: 0;
    left: 0;
    z-index: 1;
  }
}

.banner-prev,
.banner-next {
  position: absolute;
  width: 36px;
  height: 36px;
  display: grid;
  place-items: center;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  z-index: 1;
  cursor: pointer;
  &.swiper-button-disabled {
    opacity: 0;
  }
  .icon svg {
    color: var(--secondary);
  }
}

.banner-prev {
  left: 1.5rem;
  right: initial;
}
.banner-next {
  left: initial;
  right: 1.5rem;
}

.btn-share {
  position: relative;
  top: 5px;
}

.reactions {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 15px;
  .item {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &:not(:first-child) {
      margin-left: 25px;
    }
    svg {
      color: var(--secondary);
    }
    p {
      margin: -2px 0 0 6px;
      color: var(--dark5);
    }
  }
}

.title {
  position: relative;
  color: var(--dark4);
  margin: 15px 0 0 0;
}

.flex-start.prepare {
  flex-wrap: nowrap;
  margin-top: 45px;
  align-items: center;
  .title-holder {
    width: initial;
  }
}

.desc {
  position: relative;
  font-size: 1.1em;
  margin: 15px 0 -15px 0;
  &.yield {
    font-size: 1.6em;
    font-family: fontRegular;
    margin: 0;
    margin-left: 15px;
    top: 3px;
    border-bottom: 1px solid #ccc;
    color: var(--dark4);
  }
}

.title-holder {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 40px;
  &.prepare {
    margin-top: 0;
    h1 {
      margin-top: 7px;
    }
  }
  span {
    position: relative;
    margin-right: 15px;
  }
  svg {
    color: var(--secondary);
  }
  h1 {
    font-family: fontLight;
    font-size: 1.2em;
    color: var(--secondary);
  }
}

.material-design-icon.icon {
  width: 2.4em;
  height: 2.4em;
}

.material-design-icon.icon > .material-design-icon__svg {
  width: 2.4em;
  height: 2.4em;
}

.ingredients {
  position: relative;
  width: 100%;
  margin-top: 5px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  tr {
    &:first-child {
      border-radius: 12px 12px 0 0;
      td:last-child {
        border-radius: 0 12px 0 0;
      }
    }
    position: relative;
    width: 100%;
    &:nth-child(odd) {
      background-color: #ffebe8;
    }
    td {
      padding: 12px 8px;
      color: var(--dark4);
      &:nth-child(1) {
        width: 100%;
        font-family: fontMedium;
        font-size: 1.1em;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      &:nth-child(2) {
        text-align: right;
      }
      &:nth-child(3) {
        text-align: right;
      }
    }
  }
}

.ingredient-image {
  position: relative;
  max-width: 22px;
  max-height: 20px;
  margin-right: 10px;
}

.holder {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 60% 40%;
  padding-bottom: 95px;
  @media screen and (max-width: 890px) {
    grid-template-columns: 1fr;
  }
}

.related-holder {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 20px;
  margin-top: 30px;
}

@media screen and (min-width: 1160px) {
  .img-holder {
    height: 450px;
  }
}
</style>
