<template>
  <div class="ingredient-modal-holder">
    <div class="header">
      <ArrowLeft @click="close" class="back-btn" />
      <p class="title">Ingrediente</p>
    </div>

    <div v-if="userLoggedIn" class="observation text-center mt-2">
      Informe a quantidade de compra e o valor que você paga neste produto para
      calcularmos automaticamente o seu custo em todas as receitas.
    </div>

    <div v-if="!userLoggedIn" class="observation text-center mt-2">
      Informe a quantidade de compra e o valor que você paga neste produto para
      calcularmos automaticamente o seu custo na receita.
    </div>

    <div class="card name">
      <div class="header">
        <Bowl :fillColor="'var(--blue1)'" :size="30" />
        <p>Nome do ingrediente</p>
      </div>
      <div class="input">
        <input :disabled="editing" v-model="ingredient.name" type="text" />
      </div>
    </div>

    <div class="card">
      <div class="header">
        <Weight :fillColor="'var(--secondary)'" :size="30" />
        <p>Quantidade comprada</p>
      </div>
      <div class="input">
        <input v-model="ingredient.weight" type="number" min="1" />
        <p style="color: var(--secondary)">gr</p>
      </div>
    </div>
    <div class="card">
      <div class="header">
        <Currency :fillColor="'var(--green1)'" :size="30" />
        <p>Valor pago</p>
      </div>
      <div class="input">
        <money v-model="ingredient.price" />
        <p style="color: var(--green1)">R$</p>
      </div>
    </div>

    <div class="flex flex-center mt-2">
      <div @click="save" class="btn">
        <span v-show="!saving">Salvar</span>
        <div v-show="saving" class="loading2 white"></div>
      </div>
    </div>
  </div>
</template>

<script>
import authentication from "../util/authentication";
import http from "@/http";
import { Money } from "v-money";
import ArrowLeft from "vue-material-design-icons/ArrowLeft";
import Bowl from "vue-material-design-icons/BowlMix";
import Weight from "vue-material-design-icons/WeightGram";
import Currency from "vue-material-design-icons/CurrencyUsd";
export default {
  props: ["ingredientData", "editing"],
  components: {
    Money,
    ArrowLeft,
    Bowl,
    Weight,
    Currency,
  },
  data() {
    return {
      saving: false,
      ingredient: {
        name: "",
        weight: null,
        price: 0,
      },
    };
  },
  watch: {
    ingredientData: {
      handler: function (val) {
        if (val) {
          this.ingredient.name = val?.name;
          this.ingredient.weight = val?.weight ? val.weight : null;
          if (val?.price && val?.price > 0) this.ingredient.price = val.price;
        }
      },
    },
  },
  computed: {
    userLoggedIn() {
      return authentication.isLoggedIn();
    },
  },
  methods: {
    save() {
      if (
        !this.ingredient.name ||
        !this.ingredient.weight ||
        !this.ingredient.price
      ) {
        this.$swal({
          icon: "warning",
          text: "Por favor, preencha todos os campos.",
        });
        return;
      }

      this.saving = true;
      this.ingredient.weight = Number(this.ingredient.weight);

      if (this.userLoggedIn) {
        http
          .post("ingredient", this.ingredient, authentication.getHeaders())
          .finally(() => {
            this.$emit("saveIngredient", { ...this.ingredient });
            this.resetIngredient();
          });
      } else {
        this.$emit("saveIngredient", { ...this.ingredient });
        this.resetIngredient();
      }
    },
    resetIngredient() {
      this.saving = false;
      this.ingredient.name = "";
      this.ingredient.weight = null;
      this.ingredient.price = 0;
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.ingredient-modal-holder {
  position: fixed;
  width: 100vw;
  max-width: 600px;
  height: 80vh;
  padding: 20px 15px 35px 15px;
  background-color: #f1f1f1;
  border-radius: 14px 14px 0 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  overflow: auto;
  box-shadow: 0 -5px 10px 0 rgba(0, 0, 0, 0.12);
  z-index: 1000;
  @media screen and (min-width: 1160px) {
    height: 100vh;
    left: initial;
    border-radius: 14px 0 0 14px;
  }
  > .header {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      position: absolute;
      left: 0;
      margin-top: 2px;
    }
    p {
      font-size: 1.32em;
      color: var(--primary);
    }
    .back-btn {
      cursor: pointer;
    }
  }

  &::-webkit-scrollbar {
    width: 0.5em;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--dark4);
    outline: 1px solid slategrey;
  }
}

.cards-holder {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}

.card {
  position: relative;
  padding: 15px;
  border-radius: 12px;
  margin-top: 25px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  &.name {
    padding: 18px;
    .input input {
      width: 80%;
      max-width: initial;
      text-align: left;
    }
  }
  .header {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    p {
      margin-left: 15px;
      font-family: fontLight;
      font-size: 1.05em;
    }
  }
}

.input {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
  input {
    position: relative;
    min-width: 0;
    width: 50%;
    min-width: 64px;
    max-width: 220px;
    height: 38px;
    min-width: 0;
    font-size: 2em;
    border-bottom: 1px solid #777;
    text-align: center;
  }
  p {
    font-family: fontLight;
    font-size: 3.2em;
  }
}
</style>
