<template>
  <div class="navbar">
    <div @click="openCalculator" class="item" to="/home">
      <Calculator class="icon" />
      <p>Calculadora</p>
    </div>
    <div @click="like" class="item" to="/produtos">
      <div v-show="!liked">
        <Like class="icon" />
      </div>
      <div v-show="liked">
        <Liked class="icon" />
      </div>
      <p v-show="!liked">Curtir</p>
      <p v-show="liked">Desfazer</p>
    </div>
    <div @click="favorite" class="item" to="/artigos">
      <div v-show="!isFavorite">
        <Favorite class="icon" />
      </div>
      <div v-show="isFavorite">
        <Favorited class="icon" />
      </div>
      <p v-show="isFavorite">Remover</p>
      <p v-show="!isFavorite">Favoritar</p>
    </div>
  </div>
</template>
<script>
import http from "@/http";
import authentication from "@/util/authentication";
import Calculator from "vue-material-design-icons/CalculatorVariant.vue";
import Like from "vue-material-design-icons/ThumbUpOutline.vue";
import Liked from "vue-material-design-icons/ThumbUp.vue";
import Favorite from "vue-material-design-icons/HeartOutline.vue";
import Favorited from "vue-material-design-icons/Heart.vue";

export default {
  components: {
    Calculator,
    Like,
    Liked,
    Favorite,
    Favorited,
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    liked: {
      type: Boolean,
      default: false,
    },
    isFavorite: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    openCalculator() {
      this.$emit("openCalculator", true);
    },
    postFavorite(msg) {
      http
        .post(
          `user/favorite?recipeId=${this.id}`,
          "",
          authentication.getHeaders()
        )
        .then((response) => {
          if (response.status === 200) {
            this.$emit("favorite");
            this.$swal({
              toast: true,
              icon: "success",
              title: msg,
              position: "top-right",
              showConfirmButton: false,
              timer: 4000,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    favorite() {
      if (this.isFavorite) {
        this.$swal
          .fire({
            icon: "error",
            title: "Remover favoritos",
            text:
              "Tem certeza que deseja remover esta receita do seus favoritos?",
            confirmButtonText: "Remover",
            confirmButtonColor: "var(--red1)",
            showCancelButton: true,
          })
          .then((result) => {
            if (result.value) {
              this.postFavorite("Removido de seus favoritos");
            }
          });
      } else {
        this.postFavorite("Adicionado ao seus favoritos");
      }
    },
    postLike(msg) {
      http
        .post(`user/like?recipeId=${this.id}`, "", authentication.getHeaders())
        .then((response) => {
          if (response.status === 200) {
            this.$emit("like");
            this.$swal({
              toast: true,
              icon: "success",
              title: msg,
              position: "top-right",
              showConfirmButton: false,
              timer: 4000,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    like() {
      if (this.liked) {
        this.$swal
          .fire({
            icon: "error",
            title: "Remover like",
            text: "Tem certeza que deseja remover seu like desta receita?",
            confirmButtonText: "Remover",
            confirmButtonColor: "var(--red1)",
            showCancelButton: true,
          })
          .then((result) => {
            if (result.value) {
              this.postLike("Like removido");
            }
          });
      } else {
        this.postLike("Receita curtida!");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.navbar {
  position: fixed;
  width: 100vw;
  max-width: 1340px;
  height: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0 -2px 4px -2px rgba(0, 0, 0, 0.1);
  z-index: 999;
  background-color: #fff;
}

.item {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  span svg {
    color: var(--blue1);
  }

  p {
    color: var(--dark5);
    font-size: 1em;
  }
}

.div-active * {
  color: var(--secondary) !important;
}

.material-design-icon.icon {
  height: 2em;
  width: 2em;
}

.material-design-icon.icon > .material-design-icon__svg {
  height: 2em;
  width: 2em;
}
</style>
